<template>
  <input type="date" :value="startDate" @change="setDate">
  <span>~</span>
  <input type="date" :value="endDate" @change="setDate">
  <ui-button :text="$t('front.common.today')" :className="'bga'" @click="calculateDate(0)" />
  <ui-button :text="$t('front.common.aWeek')" :className="'bgb'" @click="calculateDate(7)" />
  <ui-button :text="$t('front.common.15day')" :className="'bgc'" @click="calculateDate(15)" />
  <ui-button :text="$t('front.common.1month')" :className="'bgd'" @click="calculateDate(30)" />
</template>

<script>
import { getDateStr, getSubDaysDate } from '@/libs/utils'
import UiButton from '@/components/ui/UiButton'

export default {
  name: 'UiDate',
  components: {
    UiButton
  },
  props: [
    'range'
  ],
  emits: [
    'input'
  ],
  data () {
    return {
      startDate: '',
      endDate: ''
    }
  },
  created () {
    let range = 0
    if (this.range) {
      range = this.range
    }
    this.calculateDate(range)
  },
  methods: {
    calculateDate (range) {
      const today = new Date()
      this.endDate = getDateStr(today)
      this.startDate = getDateStr(getSubDaysDate(today, range))
      this.setDate()
    },
    setDate () {
      this.$emit('input', this.startDate, this.endDate)
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
